import Input from "../../../components/Input"
import styles from "../login.module.scss"

type LoginProps = {
    value?: string | number
    [key: string]: any
}

const LoginForm = (props: LoginProps) => {
    return (
        <>
            <Input
                style={{margin: "0px"}}
                label="Mobile Number"
                type="number"
                placeholder="Enter Mobile Number"
                value={props.value}
                className={styles.login_input}
                labelFontWeight="500"
                {...props}
                onChange={props.changehandler}
            />
        </>
    )
}

export default LoginForm
