import {createSlice, PayloadAction} from "@reduxjs/toolkit"
import {RootState} from "../../store/store"

const modulesSlice = createSlice({
    name: "modules",
    initialState: {
        menuList: [],
        stakeholderMappingTab: false,
        bulkActionsButton: false,
        sessionModuleReport: false,
        logReport: false,
        complaintAddButton: false,
        commentAddButton: false,
    } as {
        menuList: null | [] | undefined
        bulkActionsButton: null | boolean | undefined
        stakeholderMappingTab: null | boolean | undefined
        sessionModuleReport: null | boolean | undefined
        logReport: null | boolean | undefined
        complaintAddButton: null | boolean | undefined
        commentAddButton: null | boolean | undefined
    },
    reducers: {
        setMenuList: (state, {payload: {menuList}}: PayloadAction<{menuList: null | [] | undefined}>) => {
            state.menuList = menuList
        },
        setStakeHolderMappingTab: (
            state,
            {payload: {stakeholderMappingTab}}: PayloadAction<{stakeholderMappingTab: null | boolean | undefined}>
        ) => {
            state.stakeholderMappingTab = stakeholderMappingTab
        },
        setBulkActionsButton: (
            state,
            {payload: {bulkActionsButton}}: PayloadAction<{bulkActionsButton: null | boolean | undefined}>
        ) => {
            state.bulkActionsButton = bulkActionsButton
        },
        setSessionModuleReport: (
            state,
            {payload: {sessionModuleReport}}: PayloadAction<{sessionModuleReport: null | boolean | undefined}>
        ) => {
            state.sessionModuleReport = sessionModuleReport
        },
        setLogReport: (state, {payload: {logReport}}: PayloadAction<{logReport: null | boolean | undefined}>) => {
            state.logReport = logReport
        },
        setComplaintAdd: (
            state,
            {payload: {complaintAddButton}}: PayloadAction<{complaintAddButton: null | boolean | undefined}>
        ) => {
            state.complaintAddButton = complaintAddButton
        },
        setCommentAdd: (
            state,
            {payload: {commentAddButton}}: PayloadAction<{commentAddButton: null | boolean | undefined}>
        ) => {
            state.commentAddButton = commentAddButton
        },
    },
})

export const {
    setMenuList,
    setBulkActionsButton,
    setStakeHolderMappingTab,
    setSessionModuleReport,
    setLogReport,
    setComplaintAdd,
    setCommentAdd,
} = modulesSlice.actions

export default modulesSlice.reducer

export const getMenuList = (state: RootState) => state.modules.menuList
export const getBulkActionsButton = (state: RootState) => state.modules.bulkActionsButton
export const getStakeholderMappingTabData = (state: RootState) => state.modules.stakeholderMappingTab
export const getSessionModuleReport = (state: RootState) => state.modules.sessionModuleReport
export const getLogReport = (state: RootState) => state.modules.logReport
export const getComplaintAddButton = (state: RootState) => state.modules.complaintAddButton
export const getCommentAddButton = (state: RootState) => state.modules.commentAddButton
