import {createSlice, PayloadAction} from "@reduxjs/toolkit"
import {RootState} from "../../store/store"

const slice = createSlice({
    name: "auth",
    initialState: {token: null, phone: null} as {
        token: null | string | undefined
        phone: null | string
    },
    reducers: {
        setCredentials: (state, {payload: {token}}: PayloadAction<{token: string | undefined}>) => {
            const localToken = localStorage.getItem("token")
            if (localToken) {
                state.token = localToken
                return
            }
            state.token = token
            if (token) {
                localStorage.setItem("token", token)
            }
        },
        setPhoneNumber: (state, {payload: {phone}}: PayloadAction<{phone: string}>) => {
            state.phone = phone
        },
        deleteCredentials: () => {
            localStorage.clear()
        },
    },
    // extraReducers: builder => {},
})

export const {setCredentials, setPhoneNumber, deleteCredentials} = slice.actions

export default slice.reducer
export const selectCurrentToken = (state: RootState) => state.auth.token
export const currentPhoneNumber = (state: RootState) => state.auth.phone
