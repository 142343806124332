import {PayloadAction, createSlice} from "@reduxjs/toolkit"

export const toastSlice: any = createSlice({
    name: "toast",
    initialState: {alert_type: "", alert_msg: "", alert_loading: null} as {
        alert_type: string
        alert_msg: string
        alert_loading: null | boolean
    },
    reducers: {
        setAlertLoading: (state, {payload: {alert_loading}}: PayloadAction<{alert_loading: boolean}>) => {
            state.alert_loading = alert_loading
        },
        setAlertFunc: (state, {payload: {message, type}}: PayloadAction<{message: string; type: string}>) => {
            state.alert_loading = true
            state.alert_msg = message
            state.alert_type = type
        },
    },
})

export const {setAlertLoading, setAlertFunc} = toastSlice.actions

export default toastSlice.reducer
