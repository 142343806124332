import {Outlet, Navigate, useLocation} from "react-router-dom"
import Hub from "../layout/Hub"
import userInfo from "../pages/Common/userInfo"

const RequiredAuth = () => {
    const token = userInfo("token")

    const location = useLocation()

    return (
        <>
            {token ? (
                <Hub>
                    <Outlet />
                </Hub>
            ) : (
                <Navigate to="/auth" replace state={{from: location}} />
            )}
        </>
    )
}

export default RequiredAuth
