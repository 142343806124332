export const rolesPermissions = [
    "company-module",
    "company-profile",
    "admin",
    "settlement",
    "registration",
    "mapping",
    "location-module",
    "station",
    "charger",
    "network-module",
    "tariff-module",
    "token-module",
    "session-module",
    "log-module",
    "complaint-module",
]

export const allModulePermissions = [
    "stakeholder-mapping",
    "bulk-actions",
    "session-report",
    "log-report",
    "complaint-add",
    "comment-add",
]
