import {ComponentsOverrides, ComponentsProps, Theme} from "@mui/material"

type muiAppBar = {
    defaultProps?: ComponentsProps["MuiAppBar"]
    styleOverrides?: ComponentsOverrides<Theme>["MuiAppBar"]
}

export const MuiAppBar: muiAppBar = {
    styleOverrides: {
        colorPrimary: {
            backgroundColor: "#f4f4f7",
            boxShadow: "none",
        },
    },
}
