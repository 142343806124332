import {ComponentsOverrides, ComponentsProps, ComponentsVariants, Theme} from "@mui/material"

type muiInput = {
    defaultProps?: ComponentsProps["MuiInputBase"]
    styleOverrides?: ComponentsOverrides<Theme>["MuiInputBase"]
    variants?: ComponentsVariants["MuiInputBase"]
}

type muiInputOutlined = {
    defaultProps?: ComponentsProps["MuiOutlinedInput"]
    styleOverrides?: ComponentsOverrides<Theme>["MuiOutlinedInput"]
    variants?: ComponentsVariants["MuiOutlinedInput"]
}

export const MuiInputBase: muiInput = {
    styleOverrides: {
        root: {
            background: "#fff",
            fontSize: "12px",
            "& $notchedOutline": {
                borderColor: "#F5F6F7",
            },
            "& $disabled": {
                color: "#101941",
            },
            "&$focused $notchedOutline": {
                borderColor: "#101941",
                borderWidth: 2,
            },
            "&:hover:not($disabled):not($focused):not($error) $notchedOutline": {
                borderColor: "#101941",
            },
            "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
                display: "none",
            },
            "& input[type=number]": {
                MozAppearance: "textfield",
            },

            "input::placeholder": {
                fontFamily: "'MarkPro'",
            },
        },
    },
}

export const MuiOutlinedInput: muiInputOutlined = {
    styleOverrides: {
        root: {
            background: "#fff",
            fontSize: "12px",
            // marginRight: 8,
            borderRadius: 5,
            "& $notchedOutline": {
                borderColor: "#F5F6F7",
            },
            "& $disabled": {
                color: "#101941",
            },
            "&$focused $notchedOutline": {
                borderColor: "#101941",
                borderWidth: 2,
            },
            "&:hover:not($disabled):not($focused):not($error) $notchedOutline": {
                borderColor: "#101941",
            },
        },
        input: {
            padding: 15,
        },
    },
}
