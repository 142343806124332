export const typography = {
    // grey: {
    //     // color: palette.secondary.grey,
    // },
    // orange: {
    //     // color: palette.primary.orange,
    // },
    xs: {
        fontSize: 10,
        color: "#404767",
    },
    sm: {
        fontSize: 12,
        color: "#70758D",
    },
    url: {
        color: "#FC441E",
        textDecoration: "underline",
    },
    orange: {
        color: "#FC441E",
    },
}
