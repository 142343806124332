import {mainSplitApi} from "../../../api"
import urls from "../../../constants/urls"

const user = mainSplitApi.injectEndpoints({
    endpoints: build => ({
        getProfileData: build.query({
            query: () => "user/v1/profile",
            transformResponse: (response: any) => {
                return response.data
            },
            providesTags: ["user"],
        }),

        updatePhone: build.mutation({
            query: body => ({
                url: `user/v1/profile`,
                method: "POST",
                body,
            }),
            invalidatesTags: (result, error) => (error ? [] : ["user"]),
        }),

        editProfileData: build.mutation({
            query: body => ({
                url: `user/v1/profile`,
                method: "PATCH",
                body,
            }),
            invalidatesTags: (result, error) => (error ? [] : ["user"]),
        }),
        logoutApi: build.mutation({
            query: () => ({
                url: `${urls.AUTH_URL}/v1/logout`,
                method: "POST",
            }),
        }),
    }),
})

export const {useGetProfileDataQuery, useUpdatePhoneMutation, useEditProfileDataMutation, useLogoutApiMutation} = user
