import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react"
import urls from "../constants/urls"
import userInfo from "../pages/Common/userInfo"

export const mainSplitApi = createApi({
    baseQuery: fetchBaseQuery({
        baseUrl: urls.BASE_URL,
        prepareHeaders: headers => {
            const token = userInfo("token")

            if (token) {
                headers.set("authorization", `${token}`)
            }
            return headers
        },
    }),

    endpoints: () => ({}),

    tagTypes: [
        "State",
        "City",
        "token",
        "registered",
        "metric",
        "details",
        "auth",
        "mapped",
        "admin",
        "user",
        "toast",
        "registered_details",
        "registered_metric",
        "error404",
        "table_filters",
        "broadcast",
        "user",
        "settlements",
        "fiscalyear",
        "settlements_charger",
        "sessions",
        "complaints",
        "uptime",
        "stakeholder",
    ],
})
