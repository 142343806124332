import {useState} from "react"
import {useFormik} from "formik"
import * as yup from "yup"
import {useLoginApiMutation, useSendOtpMutation} from "./Services/loginService"
import {useDispatch, useSelector} from "react-redux"
import {currentPhoneNumber, setPhoneNumber} from "./authSlice"
import {useNavigate} from "react-router-dom"
import {handleEnableModulePermissions} from "../../constants/modulePermissions"
import {rolesPermissions} from "../../constants/permissions"
import {handleMenuList, MenuList} from "../../constants/menuItems"
import {setMenuList} from "../Common/modulesSlice"

export const useLogin = () => {
    const [sendOtp, {data, isSuccess, isError, error, isLoading}] = useSendOtpMutation()
    const [loginApi, {error: loginError, isLoading: loginLoading}] = useLoginApiMutation()
    const currentPhone: any = useSelector(currentPhoneNumber)
    const navigate = useNavigate()

    const [optValue, setOtpValue] = useState("")
    const [renderOTPForm, setRenderOTPForm] = useState(false)

    const dispatch = useDispatch()

    const validationSchema = yup.object({
        phone: yup.string().required("Number is required"),
    })

    const formik = useFormik({
        initialValues: {
            phone: "",
        },
        validationSchema: validationSchema,
        onSubmit: (values, {setErrors}) => {
            sendOtp(values)
                .unwrap()
                .then(() => {
                    dispatch(setPhoneNumber({phone: values?.phone}))
                    setRenderOTPForm(true)
                })
                .catch(error => {
                    setErrors({phone: error.data.meta.message})
                })
        },
    })

    const otpFormik = useFormik({
        initialValues: {
            otp: "",
        },
        validationSchema: validationSchema,
        onSubmit: () => {
            // console.log("values", JSON.stringify(values, null, 2));
        },
    })

    const handleOtpSubmit = (e: any) => {
        e.preventDefault()
        const obj = {phone: currentPhone, otp: optValue}
        loginApi(obj)
            .unwrap()
            .then(values => {
                if (values.meta.status_code === 200) {
                    const data: any = values?.data
                    const encodedData = window.btoa(JSON.stringify(data))
                    const encodedkey = window.btoa("user_info")
                    localStorage.setItem(`${encodedkey}`, encodedData)
                    handleEnableModulePermissions()
                    const allPermissions = rolesPermissions
                    const currentPermissions =
                        data?.permissions.length > 0
                            ? data?.permissions.filter((item: any) => allPermissions.includes(item))
                            : []

                    const currentMenuPermissions = handleMenuList(MenuList, currentPermissions)
                    dispatch(setMenuList({menuList: currentMenuPermissions ?? []}))
                    if (currentPermissions.includes("company-profile")) {
                        navigate("/company-management/profile")
                    } else {
                        navigate("/locations/stations")
                    }
                }
            })
            .catch(() => {
                // console.warn(error)
            })
    }

    const handleResendOtp = (event: any) => {
        event.preventDefault()
        const obj: any = {phone: currentPhone}
        if (currentPhone !== "") {
            setRenderOTPForm(true)
            sendOtp(obj)
                .unwrap()
                .then(value => {
                    dispatch(setPhoneNumber({phone: obj.phone}))
                })
                .catch(error => {
                    formik.setErrors({phone: error.data.meta.message})
                })
        }
    }

    return {
        states: {
            number: formik.initialValues.phone,
            isError: isError,
            error: error,
            isSuccess,
            optValue,
            isLoading,
            loginLoading,
            renderOTPForm,
            loginError,
            data,
        },
        functions: {
            formik,
            setOtpValue,
            handleOtpSubmit,
            handleResendOtp,
        },
        formik,
        otpFormik,
    }
}
