import {MuiButtonBase, MuiButton, MuiIconButton, MuiListItemButton} from "./button"
import {MuiAppBar} from "./appBar"
import {MuiFormLabel} from "./formLabel"
import {MuiInputBase, MuiOutlinedInput} from "./input"
import {MuiTypography, MuiCssBaseline} from "./typography"
import {MuiList, MuiListItemText} from "./list"
import {MuiDataGrid} from "./dataGrid"
import {MuiPagination, MuiPaginationItem} from "./pagination"
import {MuiToolTip} from "./toolTip"

export const components = {
    MuiButtonBase,
    MuiButton,
    MuiAppBar,
    MuiFormLabel,
    MuiInputBase,
    MuiOutlinedInput,
    MuiIconButton,
    MuiListItemButton,
    MuiTypography,
    MuiCssBaseline,
    MuiList,
    MuiDataGrid,
    MuiPagination,
    MuiPaginationItem,
    MuiListItemText,
    MuiToolTip,
}
