import Button from "@mui/material/Button"
import CircularProgress from "@mui/material/CircularProgress"
import Typography from "@mui/material/Typography"

type ButtonProps = {
    [key: string]: any
}

const LoadingButton = (props: ButtonProps) => {
    return (
        <Button {...props}>
            {props.loading ? (
                <>
                    {props.text !== undefined && (
                        <Typography sx={{fontSize: "12px", marginRight: "5px"}}>{props.text}</Typography>
                    )}
                    <CircularProgress
                        sx={props?.colour !== undefined ? {color: `${props.colour}`} : {color: "#fff"}}
                        size="1.5rem"
                    />
                </>
            ) : (
                props.children
            )}
        </Button>
    )
}
export default LoadingButton
