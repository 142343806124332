import {Link} from "react-router-dom"
import styles from "./AppBar.module.scss"
import MenuPopup from "../../../../components/MenuPopup"
import useUserProfile from "../../useUserProfile"
import Grid from "@mui/material/Grid"
import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"
import CircularProgress from "@mui/material/CircularProgress"
import {source} from "../../../../constants/imageUrls"
import {iconSource} from "../../../../constants/iconUrls"
import {getInitials} from "../../../../constants/getInitials"

const AppBar = () => {
    const {states, functions} = useUserProfile()

    return (
        <div style={{width: "fit-content"}}>
            {states.profileLoading ? (
                <>Loading...</>
            ) : (
                <Grid
                    onClick={functions?.handleClick}
                    container
                    sx={{gap: "9px", cursor: "pointer"}}
                    justifyContent="flex-end"
                    alignItems="center"
                >
                    <Grid item>
                        <Box className={styles.avatar_container}>
                            <img style={{width: 29, height: 29}} src={source.avatar.AvatarIcon} alt="User Profile" />
                        </Box>
                    </Grid>

                    <Grid item>
                        <Typography
                            sx={{
                                fontWeight: 500,
                                fontSize: "14px",
                                lineHeight: "18px",
                            }}
                        >
                            {getInitials(states?.profileData?.name)}
                        </Typography>
                        <Typography
                            sx={{
                                fontWeight: 400,
                                fontSize: "12px",
                                lineHeight: "15px",
                                color: "#70758D",
                                width: "70px",
                                textOverflow: "ellipsis",
                                overflow: "hidden",
                                textWrap: " nowrap",
                            }}
                        >
                            {states?.profileData?.company_name}
                        </Typography>
                    </Grid>

                    <Grid item>
                        <img src={iconSource.icons.MenuArrowIcon} alt="menu" />
                    </Grid>
                </Grid>
            )}

            <MenuPopup
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
                transformOrigin={{
                    vertical: -14,
                    horizontal: 50,
                }}
                anchorEl={states?.anchorEl}
                handleClose={functions?.handleClose}
            >
                <Box
                    sx={{
                        position: "relative",
                        width: "230px",
                        background: "#FFFFFF",
                        boxShadow: "-3px -3px 6px rgba(172, 172, 172, 0.15), 3px 3px 6px rgba(172, 172, 172, 0.15)",
                        borderRadius: "5px",
                    }}
                >
                    <Box
                        sx={{
                            padding: "12px",
                            display: "flex",
                            alignItems: "center",
                            gap: "12px",
                        }}
                    >
                        <Link to="/user-profile">
                            <Box className={styles.avatar_container}>
                                <Box className={styles.avatar_container__avatar}>
                                    <img
                                        className={styles.avatar_container__avatar__icon}
                                        src={source.avatar.AvatarIcon}
                                        alt="User Profile"
                                    />
                                    <img
                                        className={styles.avatar_container__avatar__edit_icon}
                                        src={source.avatar.AvatarEditIcon}
                                        alt="User Profile"
                                    />
                                </Box>
                            </Box>
                        </Link>

                        <Typography
                            sx={{
                                fontWeight: 500,
                                fontSize: "14px",
                                lineHeight: "18px",
                                width: "160px",
                                textOverflow: "ellipsis",
                            }}
                        >
                            {states?.profileData?.name}
                        </Typography>
                    </Box>
                    <Box
                        sx={{
                            padding: "12px",
                            paddingTop: "0px",
                        }}
                    >
                        <Typography
                            sx={{
                                fontWeight: 400,
                                fontSize: "12px",
                                lineHeight: "15px",
                                color: "#101941",
                                wordBreak: "break-all",
                                marginTop: "8px",
                            }}
                        >
                            {states?.profileData?.email}
                        </Typography>

                        <Box display={"flex"} alignItems={"center"} justifyContent={"space-between"}>
                            <Typography
                                sx={{
                                    fontWeight: 400,
                                    fontSize: "12px",
                                    lineHeight: "15px",
                                    color: "#70758D",
                                    marginTop: "8px",
                                }}
                            >
                                Company Name:
                            </Typography>
                            <Typography
                                sx={{
                                    fontWeight: 400,
                                    fontSize: "12px",
                                    lineHeight: "15px",
                                    color: "#70758D",
                                    marginTop: "8px",
                                    textOverflow: "ellipsis",
                                    width: "80px",
                                    textAlign: "right",
                                    display: "inline-block",
                                }}
                            >
                                {states?.profileData?.brand_name}
                            </Typography>
                        </Box>
                        <Box display={"flex"} alignItems={"center"} justifyContent={"space-between"}>
                            <Typography
                                sx={{
                                    fontWeight: 400,
                                    fontSize: "12px",
                                    lineHeight: "15px",
                                    color: "#70758D",
                                    marginTop: "8px",
                                }}
                            >
                                Company Type:
                            </Typography>
                            <Typography
                                sx={{
                                    fontWeight: 400,
                                    fontSize: "12px",
                                    lineHeight: "15px",
                                    color: "#70758D",
                                    marginTop: "8px",
                                }}
                            >
                                {states?.profileData?.company_role}
                            </Typography>
                        </Box>
                        <Box display={"flex"} alignItems={"center"} justifyContent={"space-between"}>
                            <Typography
                                sx={{
                                    fontWeight: 400,
                                    fontSize: "12px",
                                    lineHeight: "15px",
                                    color: "#70758D",
                                    marginTop: "8px",
                                }}
                            >
                                Role:
                            </Typography>
                            <Typography
                                sx={{
                                    fontWeight: 400,
                                    fontSize: "12px",
                                    lineHeight: "15px",
                                    color: "#70758D",
                                    marginTop: "8px",
                                }}
                            >
                                {states?.profileData?.admin_role}
                            </Typography>
                        </Box>
                    </Box>
                    {!states.logoutIsLoading ? (
                        <Box
                            onClick={() => {
                                functions.handleLogout()
                            }}
                            sx={{
                                padding: "12px",
                                borderTop: "1px solid #E8E8EC",
                                cursor: "pointer",
                            }}
                        >
                            <Typography
                                sx={{
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    lineHeight: "18px",
                                    color: "#FC441E",
                                }}
                            >
                                Logout
                            </Typography>
                        </Box>
                    ) : (
                        <Box
                            onClick={() => {
                                functions.handleLogout()
                            }}
                            sx={{
                                padding: "12px",
                                display: "flex",
                                justifyContent: "center",
                                cursor: "pointer",
                            }}
                        >
                            {" "}
                            <CircularProgress sx={{color: "#000"}} size="1.5rem" />
                        </Box>
                    )}
                </Box>
            </MenuPopup>
        </div>
    )
}

export default AppBar
