export const source = {
    avatar: {
        AvatarIcon: "https://d3orevttu06iqr.cloudfront.net/evlinq/avatar_4.svg",
        AvatarEditIcon: "https://d3orevttu06iqr.cloudfront.net/evlinq/avatar_edit_icon.png",
    },
    menu: {
        ComplaintIcon: "https://d3orevttu06iqr.cloudfront.net/evlinq/menu/complaints_icon.svg",
        ChargerManagementIcon: "https://d3orevttu06iqr.cloudfront.net/evlinq/menu/charger_management_icon.svg",
        ExpandLessIcon: "https://d3orevttu06iqr.cloudfront.net/evlinq/menu/expandLess.svg",
        ExpandMoreIcon: "https://d3orevttu06iqr.cloudfront.net/evlinq/menu/expandMore.svg",
        LocationIcon: "https://d3orevttu06iqr.cloudfront.net/evlinq/menu/location_icon.svg",
        LogsIcon: "https://d3orevttu06iqr.cloudfront.net/evlinq/menu/logs_icon.svg",
        NetworkIcon: "https://d3orevttu06iqr.cloudfront.net/evlinq/menu/network_icon.svg",
        TokenIcon: "https://d3orevttu06iqr.cloudfront.net/evlinq/menu/token_icon.svg",
        TariffIcon: "https://d3orevttu06iqr.cloudfront.net/evlinq/menu/tariff_icon.svg",
        SessionIcon: "https://d3orevttu06iqr.cloudfront.net/evlinq/menu/sessions_icon.svg",
    },
    errorPage: {
        Icon404: "https://d3orevttu06iqr.cloudfront.net/evlinq/404.svg",
        Icon500: "https://d3orevttu06iqr.cloudfront.net/evlinq/500.svg",
    },
    backgroundImages: {
        GradientPlaceholderBg1: "https://d3orevttu06iqr.cloudfront.net/evlinq/gradient_placeholder_1.png",
        GradientPlaceholderBg2: "https://d3orevttu06iqr.cloudfront.net/evlinq/gradient_placeholder_2.png",
        GradientPlaceholderBg3: "https://d3orevttu06iqr.cloudfront.net/evlinq/gradient_placeholder_3.png",
        LoginBackground: "https://d3orevttu06iqr.cloudfront.net/evlinq/login_bg.png",
        NoCommentsBg: "https://d3orevttu06iqr.cloudfront.net/evlinq/no_comments_bg.svg",
    },
    logo: {
        FullLogo: "https://d3orevttu06iqr.cloudfront.net/evlinq/full_logo.png",
        CompanyLogoIcon: "https://d3orevttu06iqr.cloudfront.net/evlinq/small_logo.png",
    },
}
