import {ComponentsOverrides, ComponentsProps, Theme} from "@mui/material"

type muiToolTip = {
    defaultProps?: ComponentsProps["MuiTooltip"]
    styleOverrides?: ComponentsOverrides<Theme>["MuiTooltip"]
}

export const MuiToolTip: muiToolTip = {
    styleOverrides: {
        tooltip: {
            fontSize: "12px",
            color: "#101941",
            backgroundColor: "white",
            boxShadow: "2px 2px 4px rgba(0, 0, 0, 0.12)",
        },
    },
}
