const userInfo = (information?: any) => {
    const decodedData: any = {}
    if (localStorage.length > 0) {
        for (let i = 0; i < localStorage.length; i++) {
            const encodedKey = localStorage.key(i)
            const encodedValue = localStorage.getItem(`${encodedKey}`)
            const decodedKey = window.atob(`${localStorage.key(i)}`)
            const decodedValue = JSON.parse(window.atob(`${encodedValue}`))
            decodedData[decodedKey] = decodedValue
        }
        return decodedData?.user_info?.[information] ?? decodedData.user_info
    } else {
        return ""
    }
}
export default userInfo
