import {ComponentsOverrides, ComponentsProps, ComponentsVariants, Theme} from "@mui/material"

type muiFormLabel = {
    defaultProps?: ComponentsProps["MuiFormLabel"]
    styleOverrides?: ComponentsOverrides<Theme>["MuiFormLabel"]
    variants?: ComponentsVariants["MuiFormLabel"]
}

export const MuiFormLabel: muiFormLabel = {
    styleOverrides: {
        root: {
            color: "#101941",
            fontSize: 14,
            fontFamily: "'MarkPro'",
        },
    },
}
