import {useRouteError} from "react-router-dom"
import FiveHundredError from "../components/500"

const ErrorBoundary = () => {
    const error: any = useRouteError()
    console.error("error", error)
    const errorComponent = () => {
        // switch (error?.status) {
        //     default:
        //         return <FiveHundredError />
        // }
        if (error?.status) {
            return <FiveHundredError />
        }
    }
    return <div>{errorComponent()}</div>
}

export default ErrorBoundary
