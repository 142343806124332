import {
    setBulkActionsButton,
    setCommentAdd,
    setComplaintAdd,
    setLogReport,
    setSessionModuleReport,
    setStakeHolderMappingTab,
} from "../pages/Common/modulesSlice"
import userInfo from "../pages/Common/userInfo"
import {store} from "../store/store"
import {allModulePermissions} from "./permissions"

export const handleEnableModulePermissions = () => {
    const userPermissions = userInfo("permissions")
    if (userPermissions.length > 0) {
        const allPermissions = allModulePermissions
        const currentPermissions =
            userPermissions.length > 0 ? userPermissions.filter((item: any) => allPermissions.includes(item)) : []
        if (currentPermissions.includes("stakeholder-mapping")) {
            store.dispatch(setStakeHolderMappingTab({stakeholderMappingTab: true}))
        }
        if (currentPermissions.includes("bulk-actions")) {
            store.dispatch(setBulkActionsButton({bulkActionsButton: true}))
        }
        if (currentPermissions.includes("session-report")) {
            store.dispatch(setSessionModuleReport({sessionModuleReport: true}))
        }
        if (currentPermissions.includes("log-report")) {
            store.dispatch(setLogReport({logReport: true}))
        }
        if (currentPermissions.includes("complaint-add")) {
            store.dispatch(setComplaintAdd({complaintAddButton: true}))
        }
        if (currentPermissions.includes("comment-add")) {
            store.dispatch(setCommentAdd({commentAddButton: true}))
        }
    }
}

export const handleDisableModulePermissions = () => {
    const userPermissions = userInfo("permissions")
    if (userPermissions.length > 0) {
        const allPermissions = allModulePermissions
        const currentPermissions =
            userPermissions.length > 0 ? userPermissions.filter((item: any) => allPermissions.includes(item)) : []
        if (currentPermissions.includes("stakeholder-mapping")) {
            store.dispatch(setStakeHolderMappingTab({stakeholderMappingTab: false}))
        }
        if (currentPermissions.includes("bulk-actions")) {
            store.dispatch(setBulkActionsButton({bulkActionsButton: false}))
        }
        if (currentPermissions.includes("session-report")) {
            store.dispatch(setSessionModuleReport({sessionModuleReport: false}))
        }
        if (currentPermissions.includes("log-report")) {
            store.dispatch(setLogReport({logReport: false}))
        }
        if (currentPermissions.includes("complaint-add")) {
            store.dispatch(setComplaintAdd({complaintAddButton: false}))
        }
        if (currentPermissions.includes("comment-add")) {
            store.dispatch(setCommentAdd({commentAddButton: false}))
        }
    }
}
