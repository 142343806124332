import {createSlice, PayloadAction} from "@reduxjs/toolkit"
import {RootState} from "../../../../../store/store"

const SettlementsSlice: any = createSlice({
    name: "settlementsDetails",
    initialState: {
        cpo_id: null,
        cpo_name: null,
        emsp_id: null,
        emsp_name: null,
        billing_month: null,
        billing_month_name: null,
        billing_year: null,
        evse_id: null,
        evse_name: null,
    } as {
        cpo_id: null | number | undefined
        cpo_name: null | string | undefined
        emsp_id: null | number | undefined
        emsp_name: null | string | undefined
        billing_month: null | number | undefined
        billing_month_name: null | string | undefined
        billing_year: null | number | undefined
        evse_id: null | number | undefined
        evse_name: null | string | undefined
    },
    reducers: {
        setSettlementsDetails: (
            state,
            {
                payload: {
                    cpo_id,
                    cpo_name,
                    emsp_id,
                    emsp_name,
                    billing_month,
                    billing_month_name,
                    billing_year,
                    evse_id,
                    evse_name,
                },
            }: PayloadAction<{
                cpo_id: null | number | undefined
                cpo_name: null | string | undefined
                emsp_id: null | number | undefined
                emsp_name: null | string | undefined
                billing_month: null | number | undefined
                billing_month_name: null | string | undefined
                billing_year: null | number | undefined
                evse_id: null | number | undefined
                evse_name: null | string | undefined
            }>
        ) => {
            state.cpo_id = cpo_id
            state.cpo_name = cpo_name
            state.emsp_id = emsp_id
            state.emsp_name = emsp_name
            state.billing_month = billing_month
            state.billing_month_name = billing_month_name
            state.billing_year = billing_year
            state.evse_id = evse_id
            state.evse_name = evse_name
        },
    },
})

export const {setSettlementsDetails} = SettlementsSlice.actions

export default SettlementsSlice.reducer
export const getSettlementsDetails = (state: RootState) => state.settlementsDetails
