import ListItem from "@mui/material/ListItem"
import ListItemText from "@mui/material/ListItemText"
import ListItemButton from "@mui/material/ListItemButton"
import ListItemIcon from "@mui/material/ListItemIcon"
import {NavLink} from "react-router-dom"
import styles from "./SideMenu.module.scss"
import Typography from "@mui/material/Typography"
import HasSubmenuItems from "./HasSubmenuItems"

type MenuItemsProps = {
    icon?: string
    name: string
    child?: any
    open?: boolean
    path?: any
    active?: any
    isHover?: any
}

const MenuItems = (props: MenuItemsProps) => {
    const navMenuItems = () => {
        return (
            <NavLink to={props.path} style={{textDecoration: "none", color: "unset"}}>
                <ListItemButton
                    sx={
                        props.open || props.isHover
                            ? {
                                  minHeight: 48,
                                  justifyContent: "initial",
                                  px: 3,
                              }
                            : {
                                  minHeight: 48,
                                  justifyContent: "center",
                                  padding: 0,
                              }
                    }
                    selected={props.path === props.active ? true : false}
                    className={styles.parent_nav}
                >
                    <ListItemIcon
                        sx={{
                            minWidth: 0,
                            mr: 1,
                            justifyContent: "center",
                            margin: 0,
                            padding: {
                                desktop_sm: 0,
                                desktop_md: 0,
                                desktop_lg: 0,
                            },
                            width: {
                                desktop_sm: 23,
                                desktop_md: 30,
                                desktop_lg: 32,
                            },
                            height: {
                                desktop_sm: 23,
                                desktop_md: 30,
                                desktop_lg: 32,
                            },
                        }}
                        className={props.path === props.active ? styles.nav_buttons_icons : ""}
                    >
                        <img src={props.icon} />
                    </ListItemIcon>
                    {(props.open || props.isHover) && (
                        <ListItemText
                            disableTypography
                            primary={<Typography variant="body2">{props.name}</Typography>}
                            sx={{width: "calc(100% - 25px)", marginLeft: "4px"}}
                            className={props.path === props.active ? styles.nav_buttons__active : styles.nav_buttons}
                        />
                    )}
                </ListItemButton>
            </NavLink>
        )
    }

    return (
        <ListItem disablePadding sx={{display: "block"}}>
            {props.child ? (
                <HasSubmenuItems
                    icon={props.icon}
                    name={props.name}
                    child={props.child}
                    open={props.open}
                    path={props.path}
                    active={props.active}
                    isHover={props.isHover}
                />
            ) : (
                navMenuItems()
            )}
        </ListItem>
    )
}

export default MenuItems
