import {useState} from "react"
import Box from "@mui/material/Box"
import AppBar from "../../pages/UserProfile/Features/AppBar"
import SideMenu from "../../components/SideMenu"
import styles from "./MiniDrawer.module.scss"
import {iconSource} from "../../constants/iconUrls"

export default function MiniDrawer({children}: any) {
    const [open, setOpen] = useState(false)
    const [hover, setHover] = useState(false)

    return (
        <Box sx={{display: "flex", height: "100vh", background: "#F4F4F7", position: "relative"}}>
            {/* <Header opened={open} /> */}
            <SideMenu open={open} setHover={(data: any) => setHover(data)} isHover={hover} />
            <Box className={open ? styles.minidrawer__open_icon_container : styles.minidrawer__close_icon_container}>
                <img
                    className={styles.minidrawer__arrow_icon}
                    src={open ? iconSource.icons.MenuBackArrowIcon : iconSource.icons.MenuOpenArrowIcon}
                    alt="EVLINQ"
                    onClick={() => setOpen(!open)}
                />
            </Box>

            <Box component="main" sx={{flexGrow: 1, flexDirection: "column", overflow: "hidden"}}>
                <Box
                    component="div"
                    sx={{display: "flex", justifyContent: "flex-end", background: "#fff", padding: "14px 32px"}}
                >
                    <AppBar />
                </Box>
                <Box
                    component="div"
                    sx={{p: "16px 16px", background: "#F4F4F7", height: "calc(100vh - 61px)", overflow: "scroll"}}
                >
                    {children}
                </Box>
            </Box>
        </Box>
    )
}
