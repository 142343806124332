import {source} from "../../constants/imageUrls"
import LoadingButton from "../Button"
import style from "./index.module.scss"

type FiveHundredErrorProps = {
    style?: any
}
const FiveHundredError = (props: FiveHundredErrorProps) => {
    const handleReload = () => {
        window.location.reload()
    }

    return (
        <div className={style.container} style={props.style !== undefined ? {...props.style} : {}}>
            <img src={source.errorPage.Icon500} />
            <p>
                There is always time for a coffee break <br />
                We will be back by the time you finish your coffee.
            </p>
            <LoadingButton type="submit" variant="contained" onClick={() => handleReload()}>
                Refresh
            </LoadingButton>
        </div>
    )
}
export default FiveHundredError
