import ListItemButton from "@mui/material/ListItemButton"
import styles from "./SideMenu.module.scss"
import ListItemIcon from "@mui/material/ListItemIcon"
import ListItemText from "@mui/material/ListItemText"
import Typography from "@mui/material/Typography"
import {source} from "../../constants/imageUrls"
import {useState} from "react"
import List from "@mui/material/List"
import CollapseSubMenuItems from "./CollapseSubMenuItems"

type HasSubmenuItemsProps = {
    icon?: string
    name: string
    child: any
    open?: boolean
    path?: any
    active?: any
    isHover?: any
}

const HasSubmenuItems = (props: HasSubmenuItemsProps) => {
    const [showChild, setShowChild] = useState(false)

    return (
        <>
            <ListItemButton
                sx={
                    props.open || props.isHover
                        ? {
                              minHeight: 48,
                              justifyContent: "initial",
                              color: "#101941",
                              fontWeight: 600,
                              px: 3,
                              paddingRight: "0",
                          }
                        : {
                              minHeight: 48,
                              justifyContent: "center",
                              color: "#101941",
                              fontWeight: 600,
                              padding: 0,
                          }
                }
                className={
                    props.active.includes(props.path) && props.child
                        ? styles.parent_nav_submenu__active
                        : styles.parent_nav_submenu
                }
                selected={!props.isHover && props.active.includes(props.path)}
                onClick={() => {
                    setShowChild(!showChild)
                }}
            >
                <ListItemIcon
                    sx={{
                        minWidth: 0,
                        mr: 1,
                        justifyContent: "center",
                        margin: 0,
                        width: {
                            desktop_sm: 23,
                            desktop_md: 30,
                            desktop_lg: 32,
                        },
                        height: {
                            desktop_sm: 23,
                            desktop_md: 30,
                            desktop_lg: 32,
                        },
                    }}
                >
                    <img src={props.icon} />
                </ListItemIcon>
                {(props.open || props.isHover) && (
                    <ListItemText
                        disableTypography
                        primary={<Typography variant="body2">{props.name}</Typography>}
                        sx={{width: "calc(100% - 40px)", marginLeft: "4px"}}
                    />
                )}
                {(props.open || props.isHover) && (
                    <ListItemIcon
                        sx={{
                            transition: "transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;",
                            transform: showChild ? "rotateX(180deg)" : "rotateX(0deg)",
                            minWidth: 0,
                            justifyContent: "center",
                            marginRight: "10px",
                        }}
                    >
                        {<img src={source.menu.ExpandMoreIcon} alt="expand" />}
                    </ListItemIcon>
                )}
            </ListItemButton>
            <List>
                {(props.isHover || props.open) &&
                    props.child.map((collapse: any) => (
                        <CollapseSubMenuItems
                            showChild={showChild}
                            key={collapse.path}
                            icon={collapse.icon}
                            name={props.open || props.isHover ? collapse.name : ""}
                            path={collapse.path}
                            submenu_path={collapse.submenu_path}
                            isActive={
                                collapse.submenu_path === (props.active || props.isHover) ||
                                collapse.path === (props.active || props.isHover)
                                    ? true
                                    : false
                            }
                        />
                    ))}
            </List>
        </>
    )
}

export default HasSubmenuItems
