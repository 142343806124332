import React from "react"

import {Menu} from "@mui/material"

const MenuPopup = (props: any) => {
    return (
        <Menu
            anchorOrigin={props?.anchorOrigin}
            transformOrigin={props?.transformOrigin}
            anchorEl={props?.anchorEl}
            open={Boolean(props?.anchorEl)}
            onClose={props?.handleClose}
        >
            {props?.children}
        </Menu>
    )
}

export default MenuPopup
