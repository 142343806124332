type LogoProps = {
    colour: string
}

const Logo = (props: LogoProps) => {
    return (
        <svg width="100%" height="100%" viewBox="0 0 128 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M35.1455 16.1029C33.1312 16.1029 32.2308 14.5449 32.2308 12.9988V4.56963H39.5334V0.310791H23.2754V4.56963H26.5337V12.507C26.5337 15.0898 27.3174 17.0806 28.8646 18.4261C30.2434 19.626 32.2083 20.2594 34.5504 20.2594C38.35 20.2594 40.9324 18.2648 41.5297 17.7572L39.1135 14.7023C38.5678 15.1036 37.0251 16.1029 35.1455 16.1029Z"
                fill={props.colour}
            />
            <path d="M97.1504 0.312805H91.3501V19.9487H97.1504V0.312805Z" fill={props.colour} />
            <path
                d="M81.9685 16.1029C79.9542 16.1029 79.0538 14.5449 79.0538 12.9988V4.56963H86.3564V0.310791H79.0538H73.3567H70.0984V4.56963H73.3567V12.507C73.3567 15.0898 74.1404 17.0806 75.6876 18.4261C77.0664 19.626 79.0313 20.2594 81.3734 20.2594C85.173 20.2594 87.7554 18.2648 88.3527 17.7572L85.9365 14.7023C85.393 15.1036 83.8481 16.1029 81.9685 16.1029Z"
                fill={props.colour}
            />
            <path
                d="M127.849 16.162H122.727C124.225 14.4761 125.112 12.391 125.112 10.1307C125.112 4.5382 119.682 0.00396729 112.986 0.00396729C106.29 0.00396729 100.86 4.5382 100.86 10.1307C100.86 15.5561 105.969 19.9841 112.386 20.2457V20.2575H112.986C112.986 20.2575 112.986 20.2575 112.988 20.2575C112.99 20.2575 112.988 20.2575 112.99 20.2575H127.854V16.162H127.849ZM106.721 10.1307C106.721 6.9853 109.526 4.4359 112.986 4.4359C116.446 4.4359 119.251 6.9853 119.251 10.1307C119.251 13.2762 116.446 15.8256 112.986 15.8256C109.526 15.8256 106.721 13.2762 106.721 10.1307Z"
                fill={props.colour}
            />
            <path
                d="M60.8551 2.9763L60.662 2.78549C58.6724 0.991468 56.0742 0.00396729 53.3504 0.00396729C47.2738 0.00396729 42.5132 4.45361 42.5132 10.1327C42.5132 15.9062 47.1728 20.2614 53.3504 20.2614C56.0765 20.2614 58.6724 19.2739 60.662 17.4799L60.8551 17.295V19.9506H66.716V0.314777H60.8551V2.9763ZM54.6079 16.1364C51.1317 16.1364 48.3045 13.5162 48.3045 10.294C48.3045 7.28037 50.6624 4.16248 54.6079 4.16248C58.0234 4.16248 60.909 6.97153 60.909 10.294C60.909 13.5162 58.0818 16.1364 54.6079 16.1364Z"
                fill={props.colour}
            />
            <path
                d="M11.9667 8.28751C8.89698 7.76032 5.71051 7.43771 5.71051 5.80106C5.71051 4.53619 7.67314 3.85163 10.0916 3.85163C13.314 3.85163 14.585 5.00043 14.585 5.90531H20.6211C20.6211 1.54419 15.371 0 10.1276 0C4.39684 0 0 2.45891 0 5.90531C0 10.4887 5.31978 11.2972 9.7256 11.9444C12.8762 12.4067 15.7123 12.7686 15.7123 14.2636C15.7123 15.5088 14.1853 16.3685 10.6036 16.3685C6.05408 16.3685 5.71275 14.3856 5.71275 14.3856H0.00898081C0.00898081 16.7953 2.917 20.2555 10.6036 20.2555C17.3381 20.2555 21.443 17.9087 21.443 14.0315C21.4408 9.82383 16.0806 8.99567 11.9667 8.28751Z"
                fill={props.colour}
            />
        </svg>
    )
}

export default Logo
