import ListItem from "@mui/material/ListItem"
import ListItemText from "@mui/material/ListItemText"
import ListItemButton from "@mui/material/ListItemButton"
import ListItemIcon from "@mui/material/ListItemIcon"
import {NavLink} from "react-router-dom"
import styles from "./SideMenu.module.scss"
import Typography from "@mui/material/Typography"

type CollapseSubMenuItemsProps = {
    icon?: string
    name?: string
    path?: any
    submenu_path?: any
    isActive?: boolean
    showChild: boolean
}

const CollapseSubMenuItems = (props: CollapseSubMenuItemsProps) => {
    return props.showChild ? (
        <ListItem
            disablePadding
            sx={{
                display: "block",
            }}
            className={styles.submenu_navbuttons}
            key={props.path}
        >
            <NavLink
                className={props.isActive || props.isActive === props.submenu_path ? styles.selected : ""}
                to={props.path}
                style={{textDecoration: "none", color: "unset"}}
            >
                <ListItemButton
                    sx={{
                        minHeight: 36,
                        justifyContent: "initial",
                        px: 2.5,
                        py: 0,
                    }}
                    selected={props.isActive ?? props.isActive === props.submenu_path}
                    className={props.isActive ? styles.selected : ""}
                >
                    <ListItemIcon
                        sx={{
                            minWidth: 0,
                            mr: 3,
                            justifyContent: "center",
                        }}
                    >
                        <img src={props.icon} />
                    </ListItemIcon>
                    <ListItemText
                        primary={<Typography variant="body2">{props.name}</Typography>}
                        sx={{marginLeft: "5px"}}
                        className={styles.nav_buttons}
                    />
                </ListItemButton>
            </NavLink>
        </ListItem>
    ) : (
        <></>
    )
}

export default CollapseSubMenuItems
