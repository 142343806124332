import {useEffect} from "react"
import {useLocation} from "react-router-dom"
import Box from "@mui/material/Box"

import Login from "../../pages/Login"
import styles from "./landing.module.scss"
import Logo from "./Logo"
import {useDispatch} from "react-redux"
import {mainSplitApi} from "../../api"

const Landing = () => {
    const location = useLocation()
    const dispatch = useDispatch()

    useEffect(() => {
        if (location.pathname === "/auth") {
            localStorage.clear()
            dispatch(mainSplitApi.util.resetApiState())
        }
    }, [location])

    return (
        <Box sx={{display: "flex"}}>
            <div className={styles.login_wrapper}>
                <div className={styles.login_wrapper__section}>
                    <div className={styles.login_wrapper__section__logo}>
                        <Logo colour={"#fff"} />
                    </div>
                    <div className={styles.login_wrapper__section__description}>
                        <span>
                            To standardize communication between different EV charging networks and enable seamless
                            roaming for EV drivers.
                        </span>
                    </div>
                </div>
            </div>
            <Login />
        </Box>
    )
}

export default Landing
