import {ComponentsOverrides, ComponentsProps, ComponentsVariants, Theme} from "@mui/material"

declare module "@mui/material/Button" {
    export interface ButtonPropsVariantOverrides {
        customVariant: true
    }
}

type muiButtonBase = {
    defaultProps?: ComponentsProps["MuiButtonBase"]
    styleOverrides?: ComponentsOverrides<Theme>["MuiButtonBase"]
    variants?: ComponentsVariants["MuiButtonBase"]
}

type muiButton = {
    defaultProps?: ComponentsProps["MuiButton"]
    styleOverrides?: ComponentsOverrides<Theme>["MuiButton"]
    variants?: ComponentsVariants["MuiButton"]
}

type muiIconButton = {
    defaultProps?: ComponentsProps["MuiIconButton"]
    styleOverrides?: ComponentsOverrides<Theme>["MuiIconButton"]
    variants?: ComponentsVariants["MuiIconButton"]
}

type muiListItemButton = {
    defaultProps?: ComponentsProps["MuiListItemButton"]
    styleOverrides?: ComponentsOverrides<Theme>["MuiListItemButton"]
    variants?: ComponentsVariants["MuiListItemButton"]
}

export const MuiButtonBase: muiButtonBase = {
    defaultProps: {
        color: "#fff",
        disableRipple: true,
    },
    styleOverrides: {
        root: {
            // width: "100%",
            textTransform: "none",
            borderRadius: "7px",
            fontWeight: 500,
            fontSize: "15px",
            transition: "none",
        },
    },
}

export const MuiListItemButton: muiListItemButton = {
    styleOverrides: {
        root: {
            "&:hover": {
                // backgroundColor: 'none'
                // borderRight: '2px solid',
                fontWeight: 500,
                // borderRadius: 'initial',
                filter: "invert(45%) sepia(51%) saturate(7065%) hue-rotate(349deg) brightness(100%) contrast(99%)",
            },
            "&.Mui-selected": {
                borderRight: "2px solid",
                borderRadius: "initial",
                background: "none",
                fontWeight: 500,
            },
        },
    },
}

export const MuiIconButton: muiIconButton = {
    styleOverrides: {
        root: {
            // width: "100%",
            "&:hover": {
                backgroundColor: "none",
            },
        },
    },
}

export const MuiButton: muiButton = {
    styleOverrides: {
        root: {
            fontFamily: "'MarkPro'",
            textTransform: "none",
            width: "fit-content",
            alignItems: "center",
        },
    },
    variants: [
        {
            props: {variant: "contained", size: "medium"},
            style: ({theme}: {theme: Theme}) => {
                return {
                    textTransform: "none",
                    borderRadius: "7px",
                    // padding: '15px 132px',
                    background: "#FC441E",
                    fontWeight: 500,
                    fontSize: "15px",
                    transition: "none",
                    boxShadow: "none",
                    padding: "0 !important",
                    "&:hover": {
                        boxShadow: "none",
                        background: "linear-gradient(45deg, #FF2C00 0%, #E62700 100%);",
                    },
                    "&:disabled": {
                        background: "#FDB3B5",
                        color: "#fff",
                    },
                }
            },
        },
        {
            props: {size: "medium"},
            style: ({theme}: {theme: Theme}) => {
                return {
                    minWidth: "160px",
                    height: "30px",
                }
            },
        },
        {
            props: {size: "small"},
            style: ({theme}: {theme: Theme}) => {
                return {
                    minWidth: "68px",
                    height: "30px",
                    borderRadius: "5px",
                    fontSize: 12,
                    textTransform: "none",
                    "&:hover": {
                        background: "linear-gradient(45deg, #FF2C00 0%, #E62700 100%);",
                    },
                    "&:disabled": {
                        background: "#FDB3B5",
                        color: "#fff",
                    },
                }
            },
        },
    ],
}
