import {mainSplitApi} from "../../../api"
import urls from "../../../constants/urls"

type metaType = {
    status_code: number
    success: boolean
    message: string
}

type responseType = {
    token?: string
}

type LoginResponse = {
    meta: metaType
    data: responseType
}

type LoginRequest = {
    phone: string | null
    otp?: string | null
}

const VERSION = "v1"

const login = mainSplitApi.injectEndpoints({
    endpoints: build => ({
        sendOtp: build.mutation<LoginResponse, LoginRequest>({
            query: body => ({
                url: `${urls.AUTH_URL}/${VERSION}/send-otp`,
                method: "POST",
                body,
            }),
        }),
        loginApi: build.mutation<LoginResponse, LoginRequest>({
            query: body => ({
                url: `${urls.AUTH_URL}/${VERSION}/login`,
                method: "POST",
                body,
            }),
        }),
    }),
})

export const {useLoginApiMutation, useSendOtpMutation} = login
