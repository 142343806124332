import {PayloadAction, createSlice} from "@reduxjs/toolkit"

export const Error500Slice: any = createSlice({
    name: "error500",
    initialState: {enableFiveHundred: false, error_loading: null} as {
        enableFiveHundred: boolean
        error_loading: null | boolean
    },
    reducers: {
        setError500Loading: (state, {payload: {error_loading}}: PayloadAction<{error_loading: boolean}>) => {
            state.error_loading = error_loading
        },
        setError500Func: (state, {payload: {enableFiveHundred}}: PayloadAction<{enableFiveHundred: boolean}>) => {
            state.error_loading = true
            state.enableFiveHundred = enableFiveHundred
        },
    },
})

export const {setError500Loading, setError500Func} = Error500Slice.actions

export default Error500Slice.reducer
