import {createSlice, PayloadAction} from "@reduxjs/toolkit"
import {RootState} from "../../store/store"

const commonSlice = createSlice({
    name: "common",
    initialState: {broadcastData: null, mappedCompanies: null} as {
        broadcastData: null | any[] | undefined
        mappedCompanies: null | any[] | undefined
    },
    reducers: {
        setBroadcast: (state, {payload: {broadcastData}}: PayloadAction<{broadcastData: null | any[] | undefined}>) => {
            state.broadcastData = broadcastData
        },
        setMappedCompanies: (
            state,
            {payload: {mappedCompanies}}: PayloadAction<{mappedCompanies: null | any[] | undefined}>
        ) => {
            state.mappedCompanies = mappedCompanies
        },
    },
})

export const {setBroadcast, setMappedCompanies} = commonSlice.actions

export default commonSlice.reducer
export const getBroadcastData = (state: RootState) => state.common.broadcastData
export const getmappedCompaniesData = (state: RootState) => state.common.mappedCompanies
