import {createSlice, PayloadAction} from "@reduxjs/toolkit"
import {RootState} from "../../../../../../store/store"

const bulkChargerDetailsSlice = createSlice({
    name: "bulkChargerDetails",
    initialState: {
        acChargers: [],
        dcChargers: [],
        acEvsesData: [],
        dcEvsesData: [],
        modifiedAcEvsesData: [],
        modifiedDcEvsesData: [],
    } as {
        acChargers: []
        dcChargers: []
        acEvsesData: []
        dcEvsesData: []
        modifiedAcEvsesData: []
        modifiedDcEvsesData: []
    },
    reducers: {
        setACConnectorsDetails: (state, {payload: {acChargers}}: PayloadAction<{acChargers: []}>) => {
            state.acChargers = acChargers
        },
        setDCConnectorsDetails: (state, {payload: {dcChargers}}: PayloadAction<{dcChargers: []}>) => {
            state.dcChargers = dcChargers
        },
        setAcEvsesData: (state, {payload: {acEvsesData}}: PayloadAction<{acEvsesData: []}>) => {
            state.acEvsesData = acEvsesData
        },
        setDcEvsesData: (state, {payload: {dcEvsesData}}: PayloadAction<{dcEvsesData: []}>) => {
            state.dcEvsesData = dcEvsesData
        },
        setModifiedAcEvsesData: (state, {payload: {modifiedAcEvsesData}}: PayloadAction<{modifiedAcEvsesData: []}>) => {
            state.modifiedAcEvsesData = modifiedAcEvsesData
        },
        setModifiedDcEvsesData: (state, {payload: {modifiedDcEvsesData}}: PayloadAction<{modifiedDcEvsesData: []}>) => {
            state.modifiedDcEvsesData = modifiedDcEvsesData
        },
    },
})

export const {setACConnectorsDetails} = bulkChargerDetailsSlice.actions
export const {setDCConnectorsDetails} = bulkChargerDetailsSlice.actions
export const {setAcEvsesData} = bulkChargerDetailsSlice.actions
export const {setDcEvsesData} = bulkChargerDetailsSlice.actions
export const {setModifiedAcEvsesData} = bulkChargerDetailsSlice.actions
export const {setModifiedDcEvsesData} = bulkChargerDetailsSlice.actions

export default bulkChargerDetailsSlice.reducer
export const getAcChargersDetails = (state: RootState) => state.bulkChargerDetails.acChargers
export const getDcChargersDetails = (state: RootState) => state.bulkChargerDetails.dcChargers
export const getAcEvsesData = (state: RootState) => state.bulkChargerDetails.acEvsesData
export const getDcEvsesData = (state: RootState) => state.bulkChargerDetails.dcEvsesData
export const getModifiedAcChargersDetails = (state: RootState) => state.bulkChargerDetails.modifiedAcEvsesData
export const getModifiedDcChargersDetails = (state: RootState) => state.bulkChargerDetails.modifiedDcEvsesData
