import MenuItems from "./menuItems"

interface ListProps {
    [key: string]: any
}

const MenuListItems = ({icon, name, child, open, path, active, isHover}: ListProps) => {
    return <MenuItems icon={icon} name={name} child={child} open={open} isHover={isHover} path={path} active={active} />
}
export default MenuListItems
