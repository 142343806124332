import {ComponentsOverrides, ComponentsProps, ComponentsVariants, Theme} from "@mui/material"

type muiPagination = {
    defaultProps?: ComponentsProps["MuiPagination"]
    styleOverrides?: ComponentsOverrides<Theme>["MuiPagination"]
    variants?: ComponentsVariants["MuiPagination"]
}
type muiPaginationItem = {
    defaultProps?: ComponentsProps["MuiPaginationItem"]
    styleOverrides?: ComponentsOverrides<Theme>["MuiPaginationItem"]
    variants?: ComponentsVariants["MuiPaginationItem"]
}

export const MuiPagination: muiPagination = {
    styleOverrides: {
        root: {
            button: {
                color: "#70758D",
                fontFamily: "'MarkPro'",
            },
        },
    },
}
export const MuiPaginationItem: muiPaginationItem = {
    styleOverrides: {
        root: {
            "&.Mui-selected": {
                backgroundColor: "#fff",
                color: " #FC441E",
            },
        },
    },
}
