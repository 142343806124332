export const getInitials = (name: any) => {
    const parts = name?.split(" ")
    let initials = ""
    for (let i = 0; i < parts?.length; i++) {
        if (parts[i]?.length > 0 && parts[i] !== "") {
            initials += parts[i][0]
        }
    }
    const firstTwoLetters: any = parts?.length > 2 ? initials.slice(0, 2) : initials
    return firstTwoLetters
}
