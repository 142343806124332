import {components} from "./Components"
import {foundations} from "./Foundation"
import {createTheme, ThemeOptions} from "@mui/material"

const themeOptions: Omit<ThemeOptions, "components"> = {
    ...foundations,
}
declare module "@mui/material/styles" {
    interface BreakpointOverrides {
        xs: true
        sm: true
        md: true
        lg: true
        xl: true
        mobile: false
        desktop_sm: true
        laptop: true
        desktop_md: true
        desktop_lg: true
    }
}

export const theme: ThemeOptions = createTheme({
    ...themeOptions,
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 900,
            lg: 1200,
            xl: 1536,
            desktop_sm: 1280,
            laptop: 1440,
            desktop_md: 1536,
            desktop_lg: 1920,
        },
    },
    components: {
        ...components,
    },
})
