import {ComponentsOverrides, ComponentsProps, ComponentsVariants, Theme} from "@mui/material"
// import { MarkProMediumFont, MarkProRegular } from "../Fonts";

import MarkProBold from "../Fonts/MarkProBold.ttf"
import MarkProMedium from "../Fonts/MarkProMedium.ttf"
import MarkPro from "../Fonts/MarkPro.ttf"

declare module "@mui/material/styles" {
    interface TypographyVariants {
        xs?: React.CSSProperties
        sm?: React.CSSProperties
        url?: React.CSSProperties
        orange: React.CSSProperties
    }

    // allow configuration using `createTheme`
    interface TypographyVariantsOptions {
        xs?: React.CSSProperties
        sm?: React.CSSProperties
        url?: React.CSSProperties
        orange: React.CSSProperties
    }
}

// Update the Typography's variant prop options
declare module "@mui/material/Typography" {
    interface TypographyPropsVariantOverrides {
        xs: true
        sm: true
        url: true
        orange: true
    }
}

type muiTypography = {
    defaultProps?: ComponentsProps["MuiTypography"]
    styleOverrides?: ComponentsOverrides<Theme>["MuiTypography"]
    variants?: ComponentsVariants["MuiTypography"]
}

type muiCssBaseline = {
    defaultProps?: ComponentsProps["MuiCssBaseline"]
    styleOverrides?: ComponentsOverrides<Theme>["MuiCssBaseline"]
    variants?: ComponentsVariants["MuiCssBaseline"]
}

export const MuiTypography: muiTypography = {
    styleOverrides: {
        root: {
            fontFamily: "MarkPro",
        },
    },
}

export const MuiCssBaseline: muiCssBaseline = {
    styleOverrides: {
        "@font-face": {
            fontFamily: '"MarkPro"',
            fontDisplay: "swap",
            fontStyle: "normal",
            fontWeight: 300,
            src: `local('MarkProt'), local('MarkPro'),

                  url(${MarkPro}) format('truetype')`,
        },
        fallbacks: [
            {
                "@font-face": {
                    fontFamily: '"MarkPro"',
                    fontDisplay: "swap",
                    fontStyle: "normal",
                    fontWeight: 500,
                    src: `local('MarkPro Medium'), local('MarkProMedium'),
                          url(${MarkProMedium}) format('truetype')`,
                },
            },
            {
                "@font-face": {
                    fontFamily: '"MarkPro"',
                    fontDisplay: "swap",
                    fontStyle: "normal",
                    fontWeight: 700,
                    src: `local('MarkPro Bold'), local('MarkProBold'),
                          url(${MarkProBold}) format('truetype')`,
                },
            },
        ],
        "*": {
            // "scrollbar-width": "thin",
            "scrollbar-color": "#d9d9d9 ",
        },
        "*::-webkit-scrollbar": {
            width: "4px",
            height: "2px",
        },
        "*::-webkit-scrollbar-track": {
            // background: "#ffffff",
        },
        "*::-webkit-scrollbar-thumb": {
            "background-color": "#d9d9d9",
            "border-radius": "6px",
        },
        body: {
            fontFamily: '"MarkPro"',
        },
    },
}
