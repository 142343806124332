import {
    ComponentsOverrides,
    ComponentsProps,
    ComponentsVariants,
    Theme,
} from "@mui/material";

type muiList = {
    defaultProps?: ComponentsProps["MuiList"];
    styleOverrides?: ComponentsOverrides<Theme>["MuiList"];
    variants?: ComponentsVariants["MuiList"];
};


type muiListItemText = {
    defaultProps?: ComponentsProps["MuiListItemText"];
    styleOverrides?: ComponentsOverrides<Theme>["MuiListItemText"];
    variants?: ComponentsVariants["MuiListItemText"];
};


export const MuiList: muiList = {
    styleOverrides: {
        root: {
            padding: 0,
        }
    }
}
export const MuiListItemText: muiListItemText = {
    styleOverrides: {
        primary: {
            fontSize: 14,
            color: '#70758D',
        }
    }
}

