import {PayloadAction, createSlice} from "@reduxjs/toolkit"

export const Error404Slice: any = createSlice({
    name: "error404",
    initialState: {enable: false, error_loading: null} as {
        enable: boolean
        error_loading: null | boolean
    },
    reducers: {
        setError404Loading: (state, {payload: {error_loading}}: PayloadAction<{error_loading: boolean}>) => {
            state.error_loading = error_loading
        },
        setError404Func: (state, {payload: {enable}}: PayloadAction<{enable: boolean}>) => {
            state.error_loading = true
            state.enable = enable
        },
    },
})

export const {setError404Loading, setError404Func} = Error404Slice.actions

export default Error404Slice.reducer
