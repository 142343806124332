import {PaletteOptions} from "@mui/material"

declare module "@mui/material/styles" {
    interface Palette {
        stateGrey: Palette["primary"]
    }
    interface PaletteOptions {
        stateGrey: PaletteOptions["primary"]
    }
}

export const palette: PaletteOptions = {
    primary: {
        main: "#101941",
        // orange : '#FC441E'
        // light: "#53B1FD66",
    },
    secondary: {
        main: "#FC441E",
        // grey : '#70758D'
    },
    text: {
        primary: "#101941",
    },
    error: {
        main: "#F94054",
    },
    stateGrey: {
        main: "#70758D",
    },

    // blue: {
    //     100: "#D1E9FF",
    // },
    // yellow: {
    //     500: "#F79009",
    //     600: "#DC6803",
    // },
}
