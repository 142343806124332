import {PayloadAction, createSlice} from "@reduxjs/toolkit"

export const userSlice: any = createSlice({
    name: "user",
    initialState: {user_details: {}, error_loading: null} as {
        user_details: any
        error_loading: null | boolean
    },
    reducers: {
        setUserLoading: (state, {payload: {error_loading}}: PayloadAction<{error_loading: boolean}>) => {
            state.error_loading = error_loading
        },
        setUserDetails: (state, {payload: {user_details}}: PayloadAction<{user_details: any}>) => {
            state.error_loading = true
            state.user_details = user_details
        },
    },
})

export const {setuserLoading, setUserDetails} = userSlice.actions

export default userSlice.reducer
